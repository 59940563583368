import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { NostrSystem } from '@snort/system';
import { SnortContext } from '@snort/system-react';

const system = new NostrSystem({});

[
  "wss://relay.snort.social",
  "wss://relay.damus.io",
  "wss://nos.lol",
  "wss://nostr.wine"
].forEach(a => system.ConnectToRelay(a, { read: true, write: false }));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <SnortContext.Provider value={system}>
      <App />
    </SnortContext.Provider>
  </React.StrictMode>
);