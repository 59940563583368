import { useEffect, useMemo, useRef, useState } from 'react';
import './App.css';
import L, { Icon } from "leaflet";
import "leaflet/dist/leaflet.css"
import { NoteCollection, RequestBuilder } from '@snort/system';
import { getHashesWithinBboxBase32, encodeBboxBase32, decodeBboxBase32, decodeBase32 } from "geohashing"
import { useRequestBuilder } from '@snort/system-react';

function App() {
  const ref = useRef<HTMLDivElement | null>(null);
  const iconRef = useRef<HTMLDivElement | null>(null);
  const [view, setView] = useState("");
  const [map, setMap] = useState<L.Map>();
  const [markers, setMarkers] = useState<Array<string>>([]);

  const sub = useMemo(() => {
    const rb = new RequestBuilder("map");
    rb.withOptions({
      skipDiff: true
    });
    if (view) {
      const bbox = decodeBboxBase32(view);
      rb.withFilter().tag("g", getHashesWithinBboxBase32(bbox.minLat, bbox.minLng, bbox.maxLat, bbox.maxLng, 3));
    }
    rb.withFilter().tag("g", ["b", "c", "f", "g", "u", "v", "y", "z", "8", "9", "d", "e", "s", "t", "w", "x", "2", "3", "6", "7", "k", "m", "q", "r", "0", "1", "4", "5", "h", "j", "n", "p"])
    return rb;
  }, [view]);

  const data = useRequestBuilder(NoteCollection, sub);

  useEffect(() => {
    if (ref.current) {
      if (!(ref.current as any)["_leaflet_id"]) {
        const map = L.map(ref.current, {
          renderer: L.canvas()
        }).setView([51.505, -0.09], 2);
        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 19,
          attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }).addTo(map);
        map.on("moveend", e => {
          const bounds = map.getBounds();
          try {
            const geo = encodeBboxBase32(bounds.getSouthWest().lat, bounds.getSouthWest().lng, bounds.getNorthEast().lat, bounds.getNorthEast().lng);
            console.debug(bounds, geo);
            if (geo) {
              setView(geo);
            }
          } catch { }
        });
        setMap(map);
      }
    }
  }, []);

  useEffect(() => {
    if (data.data && map) {
      const added: Array<string> = [];
      for (const e of data.data) {
        if (!markers.includes(e.id)) {
          const g = e.tags.filter(a => a[0] === "g").sort((a, b) => a.length > b.length ? -1 : 1)[0][1];
          const pos = decodeBase32(g);
          L.marker([pos.lat, pos.lng], {
            icon: L.divIcon({
              className: "icon",
              html: "<svg viewBox=\"0 0 18 22\" fill=\"none\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 11.5262 16.8532 13.7402 15.2923 15.767C13.988 17.4606 12.3183 19.1158 10.6506 20.769L10.6495 20.7702C10.3345 21.0825 10.0195 21.3947 9.70711 21.7071C9.31658 22.0976 8.68342 22.0976 8.29289 21.7071C7.98026 21.3945 7.6644 21.0813 7.34916 20.7688C5.68146 19.1156 4.01205 17.4606 2.70772 15.767C1.14683 13.7402 0 11.5262 0 9ZM12 9C12 10.6569 10.6569 12 9 12C7.34315 12 6 10.6569 6 9C6 7.34315 7.34315 6 9 6C10.6569 6 12 7.34315 12 9Z\" fill=\"black\" /></svg>"
            }),
          }).bindTooltip(`<pre class="json">${JSON.stringify(e, undefined, 2)}</pre>`).addTo(map);
          added.push(e.id);
        }
      }
      setMarkers(s => Array.from(new Set([...s, ...added])));
    }
  }, [data.data, map]);

  return (
    <>
      <div className="App" ref={ref}></div>
    </>
  );
}

export default App;
